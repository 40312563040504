const HistoryContent = () => {
  return (
    <div className="history__container">
      <div className="history__col">
        <h3 className="history__subtitle">Como crescer com a gente</h3>
        <p>
          Inaugurada em 1968 com a missão de atender à uma demanda dos clientes
          por artigos de vestuário femininos e masculinos, a JOGÊ abre sua
          primeira unidade no Shopping Iguatemi SP, com um mix amplo e diverso
          focado em qualidade.
        </p>
        <p>
          Três anos depois, abre a segunda loja, no mesmo Shopping, a JOGÊ
          Meias, suprindo a ausência de loja especializada nesse ramo, da qual
          permanece até hoje.
        </p>
        <p>
          Em 2001, filia-se a ABF e concede sua primeira unidade Franqueada.
        </p>
      </div>
      <div className="history__col">
        <p>
          São mais de 53 anos de uma história sólida, permanente e de muita
          expertise no varejo. Uma marca 100% brasileira, criada por brasileiras
          para você:
        </p>
        <ul>
          <li>
            01 Industria própria com equipe especializada, que investe
            continuamente em pesquisas globais e alinhadas as mudanças
            constantes do mercado;
          </li>
          <li>
            31 lojas próprias - sabemos exatamente como uma operação funciona!
          </li>
          <li>
            33 lojas franqueadas contando com todo suporte de nosso time de
            especialistas;
          </li>
          <li>
            Show Room para recebermos nossos franqueados e estes componham seus
            estoques conforme demanda.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HistoryContent;
