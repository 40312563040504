import { useState } from "react";
import Dropdown from "./Dropdown";
import SignupBtn from "../SignupBtn";
import states from "./states";
import { PatternFormat } from "react-number-format";
import useDevice from "../../hooks/useDevice";

interface User {
  name: string;
  email: string;
  phone: string;
  city: string;
  state: string;
  policy: boolean;
}

const Form = () => {
  const isMobile = useDevice(1025);
  const [user, setUser] = useState<User>({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "UF",
    policy: false,
  });
  const [dropdownActive, setDropdownActive] = useState(false);
  const [phoneFormat, setPhoneFormat] = useState("(##) #####-####");
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
    policy: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSelection = (event: React.MouseEvent) => {
    setUser({ ...user, state: event.currentTarget.textContent || user.state });
    setDropdownActive(!dropdownActive);
  };

  const validate = () => {
    const defaultErrors = {
      name: "",
      email: "",
      phone: "",
      location: "",
      policy: "",
    };

    setErrors({ ...defaultErrors });
    const { name, email, phone, city, state, policy } = user;
    const errorObject = { ...defaultErrors };

    let valid = true;

    if (!name) {
      errorObject.name =
        '* O campo "Nome e sobrenome" é de preenchimento obrigatório.';
      valid = false;
    }
    if (!email) {
      errorObject.email = '* O campo "E-mail" é de preenchimento obrigatório.';
      valid = false;
    }
    if (!phone) {
      errorObject.phone = '* O campo "Celular" é de preenchimento obrigatório.';
      valid = false;
    }
    if (!city || !state || state === "UF") {
      errorObject.location =
        '* Preencha o campo "Cidade" e o selecione um estado (UF).';
      valid = false;
    }
    if (!policy) {
      errorObject.policy =
        "* Aceite a Política de Privacidade para prosseguir.";
      valid = false;
    }

    setErrors({ ...errors, ...errorObject });

    return valid;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const valid = validate();
    if (!valid) return;
    setLoading(true);

    const url = "https://joge.myvtex.com/_v/franchises";
    const options: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: user.name,
        email: user.email,
        celular: user.phone,
        city: user.city,
        state: user.state,
      }),
    };

    try {
      const response = await fetch(url, options);

      if (response.status === 201) {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => setSuccess(false), 7000);
      }
    } catch {
      setLoading(false);
      setSuccess(false);
    }
  };

  return (
    <form id="form" className="form" onSubmit={handleSubmit}>
      <legend className="form__title">
        <span>Seja uma</span>
        <span className="cursive">franqueada</span>
      </legend>
      <p className="form__subtitle">Preencha o formulário abaixo:</p>

      <fieldset className="form__fieldset">
        <div className="form__group">
          <input
            type="hidden"
            id="g-token"
            name="g-token"
            value="03AL8dmw9HIMiSVSlAdTEDFE5IvOdPP_DBd8FBYdR-a8_dpcqI1RMt5sp27LT1Kle8N9w0w3hdXro9Tg7h49yvaRrssTuwJ3W7G9t2UIPhty0sg8r73OqtE8nUZHmq9DplyLNWy4YA1oyyER_JsasKVKeJS-ISQIk788_qL52GHw_gE7-pvF9uWiJT4IFb0kGPO1WVsiqzScA-Fgrh0f2YMhS2Asan4j6CgHg8rvKX0egVEEBjyYM_7eg3M5ab0Wb21pfVouHvpw8I5xzzC4F4gFulr1RHTduac1kCp7pNoF0yM0L3EaYyR2zU3vMsk3ho3N8hMcNo2W75WjXHwvUfxNxTp7mbkLlAj4rYxyg_5S528l5_nFXXB0nQCq9ecOtVK41S8pdqZa-j09AWlw7_mO4xhMND2Q3Em7miYnEx8GsQ0YgN-gJ8A7aekB-MsaGpE9nbafAmuXSnO3dOdTa6o_tuTL7WYllZQlXA6WuJhW3Cy83Y1xZGQVvYP8dlKWirfhQQdP5KXUeUxAxZ4n--XyJTHtS2EjEJeG4oTGoCMnP35h5CwSMfcOA"
          />
          <input
            id="name"
            type="text"
            name="name"
            placeholder="Nome e sobrenome"
            value={user.name}
            onChange={handleChange}
            required
          />
          {errors.name && <p className="form__error">{errors.name}</p>}
        </div>
        <div className="form__group">
          <input
            id="email"
            type="email"
            name="email"
            placeholder="E-mail"
            value={user.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="form__error">{errors.email}</p>}
        </div>
        <div className="form__group">
          <PatternFormat
            id="phone"
            format={phoneFormat}
            mask="_"
            value={user.phone}
            type="text"
            placeholder="Celular"
            required
            onValueChange={(values: any) => {
              setUser({ ...user, phone: values.formattedValue });
            }}
            onBlur={() => {
              if (user.phone.replace("_", "").length === 14) {
                setPhoneFormat("(##) ####-####");
              }
            }}
            onFocus={() => {
              if (user.phone.replace("_", "").length === 14) {
                setPhoneFormat("(##) #####-####");
              }
            }}
          />
          {errors.phone && <p className="form__error">{errors.phone}</p>}
        </div>
        <div className="form__group">
          <div className="form__location">
            <textarea
              id="city"
              name="city"
              placeholder={
                isMobile
                  ? `Cidade que pretende montar a \nfranquia`
                  : "Cidade que pretende montar a franquia"
              }
              maxLength={75}
              value={user.city}
              onChange={handleChange}
              required
            />
            <Dropdown
              active={dropdownActive}
              setActive={setDropdownActive}
              selected={user.state}
              handleSelection={handleSelection}
              states={states}
            />
          </div>
          {errors.location && <p className="form__error">{errors.location}</p>}
        </div>
      </fieldset>
      <fieldset className="form__fieldset">
        <SignupBtn type="button" loading={loading} />

        <div className="form__group">
          <div className="form__policy">
            <input
              id="policy"
              type="checkbox"
              name="policy"
              onChange={(event) =>
                setUser({ ...user, policy: event.target.checked })
              }
            />
            <label htmlFor="policy">
              Ao se cadastrar você concorda com nossa{" "}
              <a
                href="https://www.joge.com.br/informacoes/politica-de-privacidade"
                target="_blank"
                rel="noreferrer"
              >
                Política de Privacidade.
              </a>
            </label>
          </div>
          {errors.policy && <p className="form__error">{errors.policy}</p>}
          {success && (
            <p className="form__success">Cadastro realizado com sucesso.</p>
          )}
        </div>
      </fieldset>
    </form>
  );
};

export default Form;
