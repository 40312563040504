interface Props {
  type: "button" | "link";
  loading?: boolean;
}

const SignupBtn = ({ type, loading }: Props) => {
  const text = "Quero ser franqueada";

  if (type === "button") {
    return (
      <button type="submit" className="signup-btn">
        {loading ? (
          <svg
            width={24}
            height={24}
            viewBox="0 0 38 38"
            aria-hidden="true"
            focusable="false"
            className="spinner"
          >
            <g
              transform="translate(2 2)"
              stroke="currentColor"
              strokeWidth="4"
              fillRule="evenodd"
            >
              <circle stroke-opacity=".5" cx="17" cy="17" r="16.5" />
              <path
                d="M33.5 17C33.5 7.9 26.1.5 17 .5"
                transform="rotate(249.767 17 17)"
              />
            </g>
          </svg>
        ) : (
          text
        )}
      </button>
    );
  } else {
    return (
      <a href="#form" className="signup-btn">
        {text}
      </a>
    );
  }
};

export default SignupBtn;
