import Section from "../../components/Section";
import SignupBtn from "../../components/SignupBtn";
import benefitsImg from "./benefits.svg";

const Benefits = () => {
  return (
    <Section id="benefits">
      <h2>
        <span>Por que se tornar uma</span>{" "}
        <span className="cursive">franqueada jogê</span>
      </h2>
      <div className="benefits__content">
        <div className="benefits__image">
          <img
            src={benefitsImg}
            alt="Benefícios Jogê"
            width="567"
            height="375"
            loading="lazy"
          />
        </div>
        <div className="signup-btn__container">
          <SignupBtn type="link" />
        </div>
      </div>
    </Section>
  );
};

export default Benefits;
