import Section from "../../components/Section";
import shoppingStore from "../../assets/images/store-shopping.webp";
import streetStore from "../../assets/images/store-street.webp";

const Stores = () => {
  return (
    <Section id="stores">
      <div className="store">
        <img
          src={shoppingStore}
          alt="Franquia em shopping"
          width="500"
          height="310"
          className="store__image"
        />
        <p className="store__text">
          <span>Venha ser uma de nossas franquias </span>
          <span className="cursive">em shopping</span>
        </p>
      </div>

      <div className="store">
        <p className="store__text">
          <span>Venha ser uma de nossas franquias</span>
          <span className="cursive">em rua</span>
        </p>
        <img
          src={streetStore}
          alt="Franquia em rua"
          width="500"
          height="310"
          className="store__image"
        />
      </div>
    </Section>
  );
};

export default Stores;
