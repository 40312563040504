interface Props {
  id: string;
  children: React.ReactNode;
}

const Section = ({ id, children }: Props) => {
  return (
    <section id={id}>
      <div className="content">{children}</div>
    </section>
  );
};

export default Section;
