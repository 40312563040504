import { useEffect, useState } from "react";

const useDevice = (maxWidth: number = 640) => {
  const [isMobile, setMobile] = useState(window.innerWidth <= maxWidth);

  useEffect(() => {
    const onResize = () => {
      setMobile(window.innerWidth <= maxWidth);
    };

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [maxWidth]);

  return isMobile;
};

export default useDevice;
