import { useEffect } from "react";

interface Props {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  selected: string;
  handleSelection: (event: React.MouseEvent) => void;
  states: string[];
}

const Dropdown = ({
  active,
  setActive,
  selected,
  handleSelection,
  states,
}: Props) => {
  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && !target.className.includes("dropdown")) {
        setActive(false);
      }
    };

    document.addEventListener("click", onClick);
    return document.removeEventListener("click", onClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dropdown">
      <button
        type="button"
        onClick={() => setActive(!active)}
        className="dropdown__btn"
      >
        {selected}
      </button>
      <div
        className="dropdown__content"
        style={{ display: active ? "block" : "none" }}
      >
        {states.map((state, index) => (
          <button
            type="button"
            tabIndex={0}
            key={state}
            onClick={handleSelection}
            className="dropdown__item"
          >
            {state}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
