const Footer = () => {
  return (
    <footer>
      <div className="content">
        <p>
          © 2022 - JOGÊ - CNPJ : 62.014.808/0062-05 - RUA VERGUEIRO, 4500 TÉRREO
          - VILA MARIANA - SÃO PAULO / SP - CEP: 04102-002
        </p>
      </div>
    </footer>
  );
};

export default Footer;
