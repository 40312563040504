import Section from "../../components/Section";
import SignupBtn from "../../components/SignupBtn";
import useDevice from "../../hooks/useDevice";

const Investment = () => {
  const isMobile = useDevice(1025);

  return (
    <Section id="investment">
      <div className="investment__cta">
        <h2>
          <span className="cursive">investimento</span>
          <span>para se tornar </span>
          <span>uma franqueada</span>
        </h2>
        {isMobile ? null : <SignupBtn type="link" />}
      </div>
      <div className="invesment__values">
        <p>
          <strong>A partir de </strong>
        </p>
        <p className="investment__value">
          <span className="investment__currency">R$</span>
          <strong>420.000,00</strong>
        </p>
        <p>
          <strong>Taxa de franquia contempla</strong>
        </p>
        <p>
          Projeto Arquitetônico, suporte na implantação da loja, manuais
          operacionais e treinamento inicial.
        </p>
        <p className="investment__value">
          <span className="investment__currency">R$</span>
          <strong>45.000,00</strong>
        </p>
        <p>
          <strong>Retorno do investimento estimado</strong>
        </p>
        <p>
          Entre
          <span className="investment__value">
            <strong>24</strong>
          </span>
          e
          <span className="investment__value">
            <strong>36</strong>
          </span>
          meses
        </p>
        <p>
          <strong>Lojas a partir de</strong>
          <span className="investment__value">
            <strong>40m²</strong>
          </span>
        </p>
        {isMobile ? <SignupBtn type="link" /> : null}
      </div>
    </Section>
  );
};

export default Investment;
