import Collapsible from "../../components/Collapsible";
import Section from "../../components/Section";
import useDevice from "../../hooks/useDevice";
import HistoryContent from "./HistoryContent";

const History = () => {
  const isMobile = useDevice(1025);

  return (
    <Section id="history">
      <h2>
        <span>A</span> <span className="cursive">história da jogê,</span>
      </h2>

      {isMobile ? (
        <Collapsible>
          <HistoryContent />
        </Collapsible>
      ) : (
        <HistoryContent />
      )}
    </Section>
  );
};

export default History;
