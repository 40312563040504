import Form from "../../components/Form";
import Section from "../../components/Section";

const Intro = () => {
  return (
    <Section id="intro">
      <div className="intro__form">
        <div className="intro__highlight">
          <h1 className="cursive">Empreenda</h1>
          <p>
            Somos uma empresa 93% feminina, isso é o que vai fazer diferença na
            sua jornada exponencial de performance .
          </p>
        </div>
        <Form />
      </div>
      <div className="intro__text">
        <p>Todo o suporte que você precisa para empreender com sucesso!</p>
        <h2 className="cursive">Junte-se a nós.</h2>
      </div>
    </Section>
  );
};

export default Intro;
