import useDevice from "../../hooks/useDevice";
import Section from "../../components/Section";
import SignupBtn from "../../components/SignupBtn";
import successBanner from "../../assets/images/banner-sucesso.webp";
import successBannerMobile from "../../assets/images/banner-sucesso-mobile.webp";

const motives = [
  "Marca com 53 anos de know how em Varejo de Rua e Shoppings;",
  "Suporte total ao franqueado para aprovação e negociação de ponto;",
  "Royalties sobre Sell In;",
  "Avaliações mensais do resultado do negócio, através do suporte de consultoria de campo especializada no ramo e em negócios;",
  "Treinamentos e reciclagens constantes;",
  "Coleções semestrais criadas especialmente para os diversos momentos na vida da mulher contemporânea.",
  "Cronograma e implantação de loja acompanhada por equipe da franqueadora;",
  "Conceito arquitetônico contemporâneo;",
  "Ferramentas que auxiliam na Gestão do Negócio.",
  "Parcerias com marcas que agregam valor ao negócio.",
  "Visual Merchandising atualizado a cada estação.",
  "Intervenções especiais nas principais sazonalidades do ano.",
  "Assistências nas compras da coleção de verão e inverno.",
  "Book para marketing digital local disponibilizado ao franqueado.",
];

const Motives = () => {
  const isMobile = useDevice();

  return (
    <Section id="motives">
      <div className="motives__banner">
        {isMobile ? (
          <img
            src={successBannerMobile}
            alt="100% conforto, 100% brasileira"
            width="375"
            height="280"
            loading="lazy"
          />
        ) : (
          <img
            src={successBanner}
            alt="100% conforto, 100% brasileira"
            width="1366"
            height="500"
            loading="lazy"
          />
        )}
      </div>
      <h2>
        <span className="cursive">motivos</span>{" "}
        <span>de sermos uma franquia de sucesso</span>
      </h2>
      <ul className="motives__list">
        {motives.map((motive, index) => (
          <li key={motive} className="motives__item">
            <svg width={35} height={35} className="motives__icon">
              <use href={`/sprites.svg#motive-${index + 1}`} />
            </svg>
            {motive}
          </li>
        ))}
      </ul>
      <div className="signup-btn__container">
        <SignupBtn type="link" />
      </div>
    </Section>
  );
};

export default Motives;
