import Intro from "./sections/Intro";
import History from "./sections/History";
import Benefits from "./sections/Benefits";
import Motives from "./sections/Motives";
import Stores from "./sections/Stores";
import Footer from "./sections/Footer";
import Investment from "./sections/Investment";

function App() {
  return (
    <main>
      <Intro />
      <History />
      <Benefits />
      <Motives />
      <Stores />
      <Investment />
      <Footer />
    </main>
  );
}

export default App;
