import { useRef, useState } from "react";

interface Props {
  children: React.ReactNode;
  height?: number;
  showMore?: string;
  showLess?: string;
}

const Collapsible = ({
  children,
  height,
  showMore = "Ver mais",
  showLess = "Ver menos",
}: Props) => {
  const [open, setOpen] = useState(false);
  const content = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (content.current) {
      if (!open) {
        content.current.style.maxHeight = `${content.current.scrollHeight}px`;
      } else {
        content.current.style.maxHeight = `${
          height ?? content.current.scrollHeight * 0.25 ?? 160
        }px`;
      }
    }

    setOpen(!open);
  };

  return (
    <div className="collapsible">
      <div
        className={`collapsible__content ${
          open ? "collapsible__content--open" : "collapsible__content--closed"
        }`}
        ref={content}
      >
        {children}
      </div>
      <button type="button" className="collapsible__btn" onClick={handleClick}>
        {open ? showLess : showMore}
      </button>
    </div>
  );
};

export default Collapsible;
